.breadcrumb{
    position: relative;
    img{
      width: 100%;
      height: 320px;
      object-fit: cover;
      @media (max-width:798px){
        height: auto;
      }
    }
    .outline{
        position: absolute;
        inset: 0;
        // background-color: rgba($color: #000000, $alpha: .5);
        h2{
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%,-50%);
          @media (max-width:798px){
            font-size: 18px;
          }
          color: #000;
          p{
            color: #000;
            @media (max-width:798px){
              font-size: 11px !important;
            }
          }
        }
    }
  }