.mySwiper{
    .slider_container{
      position: relative;
      img{
        width: 100%;
        height: 450px;
        object-fit: cover;
      }
      .outline{
        position: absolute;
        inset: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
      }
     .slider_text{
      max-width: 700px;
      width: 100%;
      position: absolute;
      top: 45%;
      left: 7%;
      transform: translate(-7%,-45%);
      color: #fff;
      z-index: 1;
      h1{
        img{
          width: 130px;
          height: auto;
          object-fit: contain;
          @media (max-width:598px){
            width: 70px;
          }
        }
    
      }
      p{
        font-family: "Montserrat",sans-serif;
        font-size: 20px;
        line-height: normal;
        font-weight: 500;
        color: #fff;
      }
      @media (max-width:798px){
        max-width: 600px;
      }
      @media (max-width:598px) {
        margin-left: 10px;
      }
      
     }
    }
    .readMore{
      border: none;
      border: 1px solid #ffffff69;
      transition: 250ms;
      padding: 13px;
      background: transparent;
      border-radius: 10px;
      &:hover{
        background-color: #0000002e;
        color: #fff;
      }
      @media (max-width:598px){
        display: none;
      }
    }
    
    .swiper-pagination {
      bottom: 10px; 
    }
    
    .swiper-pagination-bullet {
      background: #fff; 
      opacity: 0.7; 
    }
    
    .swiper-pagination-bullet-active {
      background: #007bff;
    }
    
    .swiper-button-next, .swiper-button-prev {
      color: #fff; 
      background: rgb(7,22,137,0.7); 
      border-radius: 50%;
      width: 40px; 
      height: 40px;
    }
    
    .swiper-button-next::after, .swiper-button-prev::after {
      font-size: 15px; 
    }
    
    .swiper-button-next {
      right: 10px;
      font-weight: bold;
    }
    
    .swiper-button-prev {
      left: 10px; 
    }
    
    }
    .collections_slider{
      .mySwiper{
        .swiper-pagination {
          bottom: 10px; 
        }
        
        .swiper-pagination-bullet {
          background: #000; 
          opacity: 0.7; 
        }
        
    }
}